export const LOGIN_AUTHENTICATE = "login/authenticate";
export const LOGIN_VERIFY_SMS = "login/verify-sms";
export const GET_PRODUCT = "product/get-products";
export const CREATE_PRODUCT = "product/create-product";
export const UPDATE_PRODUCT = "product/update-product";
export const DELETE_PRODUCT = "product/delete-product";

export const GET_KATEGORI = "categories/categories";
export const CREATE_KATEGORI = "categories/create-categori";
export const UPDATE_KATEGORI = "categories/update-categori";
export const DELETE_KATEGORI = "categories/delete-categori";

export const GET_ATTRIBUTE = "attribute/get-attribute";
export const CREATE_ATTRIBUTE = "attribute/create-attribute";
export const UPDATE_ATTRIBUTE = "attribute/update-attribute";
export const DELETE_ATTRIBUTE = "attribute/delete-attribute";
