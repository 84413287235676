export const priceCurrency = [

    {
        showValue: "Dolar",
        key: 2,

    },

    {
        showValue: "TL",
        key: 1,

    }
]