import React, { useMemo, useRef, useState } from "react";

import JoditEditor from "jodit-react";

export default function AppHtmlEditor({ content, onChanged, label, propName }) {
  const editor = useRef(null);

  const selfChanged = (v) => {
    const object = {
      target: {
        name: propName,
        value: v,
      },
    };
    onChanged(object);
  };

  return (
    <div className="h-30 relative overflow-y-hidden">
      <div className="flex flex-col">
        <h5 className="pt-2 pb-2">{label}</h5>
        <JoditEditor
          ref={editor}
          value={content}
          tabIndex={1}
          onBlur={selfChanged}
          onChange={selfChanged}
        />
      </div>
    </div>
  );
}
