import React, { useEffect, useState } from "react";
import NetworkService from "../../../managers/network_manager";
import {
  DELETE_KATEGORI,
  GET_KATEGORI,
  GET_PRODUCT,
} from "../../../utils/constans/end_point";
import { createSnackbar } from "../../../utils/extensions/SnackbarExtension";
import { destoryModal } from "../../../utils/extensions/modal";
import KategoriRepository from "../../../repository/kategori_repository";

export default function KategoriHook() {
  var kategoriRepo = KategoriRepository({ id: "" });

  const { sendPost } = NetworkService();

  const onEditSuccess = () => {
    kategoriRepo?.getList();
  };

  async function onDelete({ id }) {
    const postData = {
      id: id,
    };
    const response = await sendPost({
      endPoint: DELETE_KATEGORI,
      postData: postData,
    });
    if (response.success) {
      createSnackbar({ data: { type: 2, message: "Kategori Silme Başarılı" } });
    } else {
      createSnackbar({ data: { type: 1, message: response.errorText } });
    }
    destoryModal();
    kategoriRepo?.getList();
  }

  return { onEditSuccess, onDelete, kategoriRepo };
}
