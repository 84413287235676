const sideBarTopItems = [
  {
    id: 1,
    name: "Ana Sayfa",
    link: "/home",
    isExpanded: false,
  },
  {
    id: 2,
    name: "Ürünler",
    link: "/home/urunler",
    isExpanded: false,
  },
  {
    id: 3,
    name: "Kategoriler",
    link: "/home/kategories",
    isExpanded: false,
  },
  {
    id: 4,
    name: "Özellikler",
    link: "/home/ozellikler",
    isExpanded: false,
  },
  {
    id: 5,
    name: "İadeler",
    link: "/home/iadeler",
    isExpanded: false,
  },
  {
    id: 6,
    name: "Siparişler",
    link: "/home/siparisler",
    isExpanded: false,
  },
  {
    id: 7,
    name: "Kargolar",
    link: "/home/kargolar",
    isExpanded: false,
  },
  {
    id: 8,
    name: "En Çok Görüntüleneler",
    link: "/home/most-view",
    isExpanded: false,
  },
];

export { sideBarTopItems as sideBarItems };
