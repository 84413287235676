import React, { useRef, useState } from "react";

export default function AppTextInput({
  onchanged,
  label,
  placeholder,
  name,
  value,
  isPassword,
  sufIcon,
  readOnly,
  onClick,
  onFocus,
  unFocus,
  isComboBox,
  prefIcon,
}) {
  const [isFocus, setIsFocus] = useState(false);
  const inputRef = useRef(null);

  const handleOnclick = () => {
    if (inputRef.current) {
      if (onFocus !== undefined) {
        onFocus();
      }

      inputRef.current.focus();
    }
  };

  return (
    <div className="flex flex-col w-full p-2">
      <h2 className="text-lg font-normal mb-1">{label}</h2>

      <div
        onClick={handleOnclick}
        className={` ${
          isFocus ? "border-green-600 border" : "border"
        } cursor-text flex items-center  rounded-lg p-2 w-full`}
      >
        <input
          onClick={onClick}
          readOnly={readOnly}
          ref={inputRef}
          onFocus={() => {
            setIsFocus(true);
          }}
          onBlur={() => {
            if (unFocus !== undefined) {
              unFocus();
            }
            setIsFocus(false);
          }}
          value={value}
          name={name}
          onChange={onchanged}
          class={`border-none focus:outline-none w-full`}
          type={isPassword == true ? "password" : "text"}
          placeholder={placeholder}
        />

        <span class="mr-1">{sufIcon}</span>
      </div>
    </div>
  );
}

<>
  <div class="flex items-center border rounded-lg p-2">
    <input
      type="text"
      placeholder="Search..."
      class="border-none focus:outline-none w-full"
    ></input>
    <span class="mr-3">
      <i class="fas fa-search text-gray-500"></i>{" "}
      <div className="w-4 h-4 bg-red-400"></div>
    </span>
  </div>
</>;
