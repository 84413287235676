import { createSlice } from '@reduxjs/toolkit'

export const productList = createSlice({
    name: 'productList',
    initialState: {
        products: [
            {
                id: 1,
                value: 4,
            },
            {
                id: 2,
                value: 2,
            },
            {
                id: 3,
                value: 22,
            },
            {
                id: 4,
                value: 3,
            },
        ],

    },
    reducers: {
        setList: (state, action) => {
            state.products = action.payload

        },
        editList: (state, action) => {
            const index = state.products.findIndex(item => item.id === action.payload.id);
            if (index === -1) {
                return;
            }
            const tempList = [...state.products];
            tempList[index] = action.payload;
            state.products = tempList;
        }
    }
})

function handleOnEdit({ list, data }) {

    const currentList = list;
    const filteredList = currentList.map(e => e.id === data.id)

    return filteredList;
}

export const { setList, editList } = productList.actions

export default productList.reducer