import React, { useRef, useState } from 'react'
import AppButton from '../AppButton'
import AppTextInput from '../AppTextInput'
import { LOGIN_VERIFY_SMS } from '../../utils/constans/end_point';
import { createSnackbar } from '../../utils/extensions/SnackbarExtension';
import { BASE_URL } from '../../utils/constans/api_config';
import NetworkService from '../../managers/network_manager';
import { useNavigate, useNavigation } from 'react-router-dom';
import { authLogin } from '../../utils/extensions/AuthExtension';

export default function VerifySmsModal({ probs, closeModal }) {

    const { sendPost } = NetworkService();
    const navigation = useNavigate();
    const [isLoading, setLoading] = useState(false);

    var formData = useRef({
        phone: probs.phone,
        smsCode: '',
    });

    function handleOnChange(e) {
        const { name, value } = e.target;
        formData.current[name] = value;
    }

    const handleOnClick = async (e) => {
        setLoading(true);
        e.preventDefault();

        const response = await sendPost({ endPoint: LOGIN_VERIFY_SMS, postData: formData.current });

        if (!response.success) {
            createSnackbar({ data: { type: 1, message: response.errorText } })
        }
        if (response.success === true) {
            authLogin({ data: response.data })
            navigation("/home", { replace: true })
            closeModal();
            createSnackbar({ data: { type: 2, message: "Giriş başarılı" } });


        }

        setLoading(false);


    }
    return (
        <div>
            <div>

                <AppTextInput
                    name={"smsCode"}
                    label={"Sms Doğrulama Kodu"}
                    placeholder={"Giriniz"}
                    onchanged={handleOnChange}
                >

                </AppTextInput>
                <AppButton isLoading={isLoading} label="Onayla" onclick={handleOnClick} >

                </AppButton>
            </div>
        </div>
    )
}
