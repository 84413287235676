import React, { useEffect, useState } from 'react'

export default function AppImagePicker({ imagePickerHook }) {

    const { image, setImage } = imagePickerHook;

    return (
        <div className='pt-10' >
            <input type="file" name="name" onChange={(v) => {
                setImage(v.target.files[0]);
            }} />
        </div>
    )
}
