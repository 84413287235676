import React, { useState } from "react";
import AttributesHook from "./AttributesHook";
import AppAddNewItem from "../../../components/AppAddNewItem";
import { createModal } from "../../../utils/extensions/modal";
import {
  areYouSore,
  createOrEditAttrbiute,
  createOrEditCategori,
} from "../../../components/modals/modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export default function AttributesScreen() {
  var attributesHook = AttributesHook();

  return (
    <div className=" h-full w-full">
      {attributesHook.attributeRepo.loading === true ? (
        <div></div>
      ) : (
        <AttributesBody attributesHook={attributesHook}></AttributesBody>
      )}
    </div>
  );
}

function AttributesBody({ attributesHook }) {
  const { refreshList } = attributesHook?.attributeRepo;
  return (
    <div className="flex flex-col">
      <AppAddNewItem
        title="Yeni Özellik Ekle"
        onClick={() => {
          createModal({
            name: createOrEditAttrbiute,
            title: "Özellik Oluştur",
            data: { refreshList },
          });
        }}
      ></AppAddNewItem>

      {attributesHook.attributeRepo.getTopAttrbiute().map((i) => (
        <AttrbiuteListTile
          attributesHook={attributesHook}
          item={i}
        ></AttrbiuteListTile>
      ))}
    </div>
  );
}

function AttrbiuteListTile({ item, attributesHook }) {
  const [hover, setHover] = useState(false);

  const { attributeRepo, onDelete } = attributesHook;

  const { refreshList, getSubAttrbiute } = attributeRepo;

  return (
    <>
      <div
        onClick={() => {
          createModal({
            title: "Özellik Güncelle",
            name: createOrEditAttrbiute,
            data: { ...item, isEdit: true, refreshList },
          });
        }}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        className=" p-2 hover:bg-slate-200 w-full flex flex-row"
      >
        <div className="flex-1"> {item?.name}</div>
        {hover === true && (
          <div className="">
            <div
              onClick={(e) => {
                e.stopPropagation();
                createModal({
                  title: "Ürün Sil",
                  name: areYouSore,
                  data: {
                    text: "Bu ürünü silmek istediğinizden emin misiniz?",
                    onContinue: () => {
                      onDelete({ id: item.id });
                    },
                  },
                });
              }}
              className="p-4 rounded-full flex items-center justify-center z-10 h-3 w-3 bg-gray-300"
            >
              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            </div>
          </div>
        )}
      </div>
      <div className="pl-10">
        {getSubAttrbiute(item.id).map((e) => (
          <AttrbiuteListTile
            item={e}
            attributesHook={attributesHook}
          ></AttrbiuteListTile>
        ))}
      </div>
    </>
  );
}
