import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
export default function SidebarListTile({ props }) {
    const navigate = useNavigate();
    const location = useLocation();
    const isCurrent = props.link === location.pathname;


    const [isExpanded, setIsExpanded] = useState(props.isExpanded)

    return (
        <div className={`pl-2 w-full h-max hid hi`}>
            <div
                onClick={() => {
                    //   setIsExpanded(!isExpanded);
                    navigate(props.link, { replace: true })
                }}
                className={`${isCurrent ? "bg-gray-200 rounded-lg " : ""} p-2 cursor-pointer hover:font-medium  w-full flex flex-row justify-between`}
            >
                <div className='text-sm h-' >{props.name}</div>
                {(props.subLinks !== undefined) ? (!isExpanded) ? <FontAwesomeIcon icon={faCaretDown} /> : <FontAwesomeIcon icon={faCaretUp} /> : null}

            </div>
            <div
                className=' transition duration-100 ease-in-out ' hidden={!isExpanded}>
                {(props.subLinks?.map((e) =>
                    <SidebarListTile key={e.id} props={e} />
                )
                )}
            </div>


        </div>
    )
}
