import React, { useState } from "react";
import { createModal } from "../../../utils/extensions/modal";
import { areYouSore, createOrEditUrun } from "../../../components/modals/modal";

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function UrunTile({ item, onEditSuccess, onDeleteSuccess }) {
  const [hover, setHover] = useState(false);

  return (
    <div
      onMouseEnter={() => {
        console.log("onHover");
        setHover(true);
      }}
      onMouseLeave={() => {
        console.log("onHoverdis");
        setHover(false);
      }}
      className="flex  w-[35vw] lg:w-[24vw] xl:w-[18vw] h-96 flex-col items-center justify-center shadow-md rounded-md z-10 relative"
    >
      <div
        onClick={(e) => {
          createModal({
            title: "Ürünü Güncelle",
            name: createOrEditUrun,
            data: { ...item, isEdit: true, onEditSuccess },
          });
        }}
        className=" flex items-center justify-center p-2 m-2"
      >
        <div className="flex flex-col justify-center items-center">
          <img className="flex h-72 w-72" src={item.imageUrl}></img>
          <div className="pt-2">
            <div className="flex flex-row justify-center items-center text-black">
              <div className="pr-5 flex">{item.name + ""}</div>

              <div className="flex">
                {" "}
                {item.price.amount}
                {item.price.currenySembol}
              </div>
            </div>
          </div>
        </div>
      </div>
      {hover === true && (
        <div className="absolute  top-0  right-0">
          <div
            onClick={() => {
              createModal({
                title: "Ürün Sil",
                name: areYouSore,
                data: {
                  text: "Bu ürünü silmek istediğinizden emin misiniz?",
                  onContinue: onDeleteSuccess,
                },
              });
            }}
            className="p-4 hover:bg-gray-200 rounded-full flex  items-center justify-center z-10 h-6 w-6 bg-gray-200"
          >
            <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
          </div>
        </div>
      )}
    </div>
  );
}
