import React, { useEffect, useState } from "react";
import NetworkService from "../../../managers/network_manager";
import { createSnackbar } from "../../../utils/extensions/SnackbarExtension";
import { destoryModal } from "../../../utils/extensions/modal";
import {
  CREATE_ATTRIBUTE,
  UPDATE_ATTRIBUTE,
  UPDATE_KATEGORI,
} from "../../../utils/constans/end_point";
import AttributeRepository from "../../../repository/attribute_repository";
import AppComboBoxHook from "../AppComboBox/AppComboBoxHook";

function CreateOrEditAttrbiuteHook(probs) {
  var attributeRepo = AttributeRepository({ id: probs?.topCategoriId });

  const attrbiuteComboBoxHook = AppComboBoxHook({
    label: "Üst Özellik",
    selectMany: false,
  });

  useEffect(() => {
    attrbiuteComboBoxHook.fillSelectedOptionFromId(probs.topCategoriId);
  }, [attrbiuteComboBoxHook.items]);

  useEffect(() => {
    attrbiuteComboBoxHook.setItems(attributeRepo.listForCombobox(probs.id));
  }, [attributeRepo.data]);

  const [isLoading, setIsLoading] = useState(false);
  const { sendPost } = NetworkService();

  const [formData, setFormData] = useState({
    name: probs?.name,
    description: probs?.description,
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  async function handleOnSave() {
    setIsLoading(true);

    if (probs.isEdit === true) {
      await editOne();
      if (probs.refreshList != undefined) {
        probs.refreshList();
      }
    } else {
      await createOne();

      if (probs.refreshList != undefined) {
        probs.refreshList();
      }
    }

    setIsLoading(false);
  }
  const editOne = async () => {
    const postData = {
      id: probs.id,
      name: formData.name,
      description: formData.description,
      topCategoriId: attrbiuteComboBoxHook.selectedOption?.key,
    };

    const response = await sendPost({
      endPoint: UPDATE_ATTRIBUTE,
      postData: postData,
    });
    if (response.success) {
      createSnackbar({
        data: { type: 2, message: "Özellik Güncelleme Başarılı" },
      });
      destoryModal();
    } else {
      createSnackbar({ data: { type: 1, message: response.errorText } });
    }
  };

  const createOne = async () => {
    const postData = {
      name: formData.name,
      description: formData.description,
      topCategoriId: attrbiuteComboBoxHook.selectedOption?.key,
    };
    const response = await sendPost({
      endPoint: CREATE_ATTRIBUTE,
      postData: postData,
    });
    if (response.success) {
      createSnackbar({
        data: { type: 2, message: "Özellik Ekleme Başarılı" },
      });
      destoryModal();
    } else {
      createSnackbar({ data: { type: 1, message: response.errorText } });
    }
  };

  return {
    createOne,
    editOne,
    handleOnChange,
    handleOnSave,
    isLoading,
    formData,
    attributeRepo,
    attrbiuteComboBoxHook,
  };
}

export default CreateOrEditAttrbiuteHook;
