import React, { useState } from "react";
import AttributeRepository from "../../../repository/attribute_repository";
import { DELETE_ATTRIBUTE } from "../../../utils/constans/end_point";
import { createSnackbar } from "../../../utils/extensions/SnackbarExtension";
import { destoryModal } from "../../../utils/extensions/modal";
import NetworkService from "../../../managers/network_manager";

export default function AttributesHook() {
  var attributeRepo = AttributeRepository({ id: "" });
  const { sendPost } = NetworkService();

  async function onDelete({ id }) {
    const postData = {
      id: id,
    };
    const response = await sendPost({
      endPoint: DELETE_ATTRIBUTE,
      postData: postData,
    });
    if (response.success) {
      createSnackbar({ data: { type: 2, message: "Özellik Silme Başarılı" } });
    } else {
      createSnackbar({ data: { type: 1, message: response.errorText } });
    }
    destoryModal();
    attributeRepo?.refreshList();
  }

  return {
    attributeRepo,
    onDelete,
  };
}
