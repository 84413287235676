import React, { useCallback, useEffect, useState } from "react";
import NetworkService from "../managers/network_manager";
import { GET_ATTRIBUTE } from "../utils/constans/end_point";
import { createSnackbar } from "../utils/extensions/SnackbarExtension";

export default function AttributeRepository({ id = "" }) {
  const [selected, setSelected] = useState(null);

  const { sendGet } = NetworkService();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (id != "") {
      setSelectedFromId(id);
    }
  }, [data]);

  const setSelectedFromId = useCallback(
    (id) => {
      {
        const tempSelected = data.find((e) => e?.id == id);

        setSelected({
          key: tempSelected?.id,
          showValue: tempSelected?.name,
        });
      }
    },
    [data]
  );

  const getList = async () => {
    setLoading(true);
    const response = await sendGet({ endPoint: GET_ATTRIBUTE });
    if (!response.success) {
      createSnackbar({ data: { type: 2, message: response.errorText } });
    }
    if (response.success) {
      setData(response.data);
    }
    setLoading(false);
  };

  const onChangedSelected = (v) => {
    setSelected(v);
  };

  const categoriFromId = () => {
    const tempSelected = data.find((e) => e?.id == selected?.key);
    return {
      key: tempSelected?.id,
      showValue: tempSelected?.name,
    };
  };

  const listForCombobox = (currentId, removeTopAttbiute) => {
    var tempData = data.filter((e) => e.id !== currentId);
    if (removeTopAttbiute === true) {
      tempData = tempData.filter((e) => e.topCategoriId !== null);
    }
    if (tempData?.length == 0) return [];
    return tempData?.map((e) => {
      return {
        key: e.id,
        showValue: e.name,
      };
    });
  };

  const refreshList = () => {
    getList();
  };

  useEffect(() => {
    getList();
  }, []);

  const getTopAttrbiute = () => {
    return data.filter((e) => e.topCategoriId === null);
  };

  const getSubAttrbiute = (id) => {
    return data.filter((e) => e.topCategoriId === id);
  };

  return {
    data,
    loading,
    selected,
    onChangedSelected,
    listForCombobox,
    refreshList,
    categoriFromId,
    getTopAttrbiute,
    getSubAttrbiute,
  };
}
