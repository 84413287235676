import React from 'react'
import { Outlet } from 'react-router-dom'


export default function Mainbar() {

    return (
        <div className='  p-2 pb-2 bg-slate-300 h-full  overflow-auto' >

            <div className='
            p-5
            w-full
            h-full 
            overflow-auto 
            overflow-x-hidden
            flex 
            justify-center 
            items-center
            flex-col 
               bg-white shadow-lg rounded-lg scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-200 scrollbar-thumb-rounded-full scrollbar-track-rounded-full'>
                <Outlet>

                </Outlet>

            </div>
        </div>

    )
}
