import { createSlice } from '@reduxjs/toolkit'

export const modal = createSlice({
    name: 'modal',
    initialState: {
        modals: [],
    },
    reducers: {
        append: (state, action) => {
            state.modals = [...state.modals, action.payload]
        },
        destory: state => {
            state.modals = state.modals.slice(0, -1)
        },
        destoryAll: state => {
            state.modals = [];
        }
    }
})

export const { append, destory, destoryAll } = modal.actions

export default modal.reducer