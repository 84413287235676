import React from "react";
import AppImagePicker from "../../app_image_picker/AppImagePicker";
import AppTextInput from "../../AppTextInput";
import AppImagePickerHook from "../../app_image_picker/app_image_picker_hook";
import CreateOrEditCategoriHook from "./CreateOrEditCategoriHook";
import AppButton from "../../AppButton";
import AppComboBox from "../AppComboBox/AppComboBox";

export default function CreateOrEditCategoriModal({ probs }) {
  const hook = CreateOrEditCategoriHook(probs);

  return (
    <div className="p-5">
      <div className="w-[80vw] h-[80vh]  overflow-auto justify-center items-start content-start self-center justify-self-center">
        <div className="flex flex-col">
          <AppTextInput
            placeholder={"Giriniz"}
            label={"Kategori Adı"}
            onchanged={hook?.handleOnChange}
            name={"name"}
            value={hook?.formData.name}
          ></AppTextInput>

          <AppTextInput
            placeholder={"Giriniz"}
            label={"Kategori Açıklaması"}
            onchanged={hook?.handleOnChange}
            name={"description"}
            value={hook?.formData.description}
          ></AppTextInput>

          <AppTextInput
            placeholder={"Giriniz"}
            label={"Breadcrumb Adı"}
            onchanged={hook?.handleOnChange}
            name={"breadcrumbName"}
            value={hook?.formData.breadcrumbName}
          ></AppTextInput>

          <AppComboBox comboboxHook={hook?.kategoriComboBoxHook}></AppComboBox>

          <AppImagePicker imagePickerHook={hook?.imagePicker}></AppImagePicker>

          <AppButton
            isLoading={hook?.isLoading}
            label={"Kaydet"}
            onclick={hook?.handleOnSave}
          ></AppButton>
        </div>
      </div>
    </div>
  );
}
