import AreYouSureModal from "./AreYouSureModal";
import CreateOrEditModal from "./CreateOrEditModal";
import VerifySmsModal from "./VerifySmsModal";
import CreateOrEditAttributeModal from "./create_or_edit_attributes_modal/CreateOrEditAttributeModal";
import CreateOrEditCategoriModal from "./create_or_edit_categori_modal/CreateOrEditCategoriModal";

const createOrEditUrun = "createOrEditUrun"
const createOrEditCategori = "createOrEditCategori";
const createOrEditAttrbiute = "createOrEditAttrbiute"
const verifySms = "verifySms"
const areYouSore = "areYouSure";

const modals = [
    {
        name: createOrEditUrun,
        element: CreateOrEditModal,

    },
    {
        name: verifySms,
        element: VerifySmsModal,

    },
    {
        name: areYouSore,
        element: AreYouSureModal,

    },
    {
        name: createOrEditCategori,
        element: CreateOrEditCategoriModal,

    },
    {
        name: createOrEditAttrbiute,
        element: CreateOrEditAttributeModal,

    }
]

export default modals



export { createOrEditUrun, verifySms, areYouSore, createOrEditCategori, createOrEditAttrbiute }