import React from 'react'
import { useSnackbars } from '../utils/extensions/SnackbarExtension'

export default function AppSnackBar() {
    const snackBars = useSnackbars();

    const calcBgColor = ({ type }) => {
        if (type === 1) return "bg-red-500"
        if (type === 2) return "bg-green-500"
    }
    return (
        <>
            {snackBars.map((e1, i) => {

                return (
                    <div className="fixed top-4 right-4 animate-expand-center z-0">
                        <div className={`${calcBgColor({ type: e1.data.type })} text-white px-4 py-2 rounded`} >
                            {e1.data.message}
                        </div>

                    </div>
                )

            })}

        </>
    )
}
