import React, { useEffect, useState } from "react";
import AppImagePickerHook from "../../app_image_picker/app_image_picker_hook";
import { createSnackbar } from "../../../utils/extensions/SnackbarExtension";
import { destoryModal } from "../../../utils/extensions/modal";
import {
  CREATE_KATEGORI,
  UPDATE_KATEGORI,
} from "../../../utils/constans/end_point";
import NetworkService from "../../../managers/network_manager";
import KategoriRepository from "../../../repository/kategori_repository";
import AppComboBoxHook from "../AppComboBox/AppComboBoxHook";

export default function CreateOrEditCategoriHook(probs) {
  const kategoriRepo = KategoriRepository({ id: probs?.topCategoriId });

  const kategoriComboBoxHook = AppComboBoxHook({
    label: "Üst Kategori",
    selectMany: false,
  });

  useEffect(() => {
    kategoriComboBoxHook.setIsLoading(kategoriRepo.loading);
  }, [kategoriRepo.loading]);

  useEffect(() => {
    kategoriComboBoxHook.setItems(kategoriRepo.listForCombobox(probs?.id));
  }, [kategoriRepo.data]);

  useEffect(() => {
    kategoriComboBoxHook.fillSelectedOptionFromId(probs?.topCategoriId);
  }, [kategoriComboBoxHook.items]);

  const imagePicker = AppImagePickerHook({ propName: "imageUrl" });
  const { sendPost, uploadFile } = NetworkService();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: probs?.name,
    description: probs?.description,
    breadcrumbName: probs?.breadcrumbName,
  });

  function handleOnChange(e) {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  async function handleOnSave() {
    setIsLoading(true);

    if (probs.isEdit === true) {
      await editOne();
      if (probs.onEditSuccess != undefined) {
        probs.onEditSuccess();
      }
    } else {
      await createOne();
      if (probs.onEditSuccess != undefined) {
        probs.onEditSuccess();
      }
    }

    setIsLoading(false);
  }
  async function editOne() {
    var bodyFormData = new FormData();
    bodyFormData.append("file", imagePicker?.image);

    const response1 = await uploadFile({ file: bodyFormData });

    const postData = {
      id: probs.id,
      name: formData.name,
      description: formData.description,
      breadcrumbName: formData.breadcrumbName,
      imageUrl: response1?.data,
      topCategoriId: kategoriComboBoxHook.selectedOption?.key,
    };

    const response = await sendPost({
      endPoint: UPDATE_KATEGORI,
      postData: postData,
    });
    if (response.success) {
      createSnackbar({
        data: { type: 2, message: "Kategori Güncelleme Başarılı" },
      });
      destoryModal();
    } else {
      createSnackbar({ data: { type: 1, message: response.errorText } });
    }
  }

  async function createOne() {
    var bodyFormData = new FormData();

    bodyFormData.append("file", imagePicker?.image);

    const response1 = await uploadFile({ file: bodyFormData });

    const postData = {
      name: formData.name,
      description: formData.description,
      breadcrumbName: formData.breadcrumbName,
      imageUrl: response1?.data,
      topCategoriId: kategoriComboBoxHook.selectedOption?.key,
    };
    const response = await sendPost({
      endPoint: CREATE_KATEGORI,
      postData: postData,
    });
    if (response.success) {
      createSnackbar({
        data: { type: 2, message: "Kategori Ekleme Başarılı" },
      });
      destoryModal();
    } else {
      createSnackbar({ data: { type: 1, message: response.errorText } });
    }
  }

  return {
    imagePicker,
    formData,
    handleOnChange,
    isLoading,
    handleOnSave,
    kategoriRepo,
    kategoriComboBoxHook,
  };
}
