import React, { useEffect, useState } from "react";

import { createModal, destoryModal } from "../../../utils/extensions/modal";
import { createOrEditUrun } from "../../../components/modals/modal";
import NetworkService from "../../../managers/network_manager";
import {
  DELETE_PRODUCT,
  GET_PRODUCT,
  UPDATE_PRODUCT,
} from "../../../utils/constans/end_point";
import { createSnackbar } from "../../../utils/extensions/SnackbarExtension";
import { useAuth } from "../../../utils/extensions/AuthExtension";
import UrunTile from "./UrunTile";
import AppAddNewItem from "../../../components/AppAddNewItem";

export default function UrunlerScreen() {
  const { sendPost } = NetworkService();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  const getList = async () => {
    setLoading(true);
    const response = await sendPost({ endPoint: GET_PRODUCT, postData: {} });
    if (!response.success) {
      createSnackbar({ data: { type: 2, message: response.errorText } });
    }
    if (response.success) {
      setData(response.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getList();
  }, []);

  function onCreateSuccess() {
    getList();
  }
  function onEditSuccess() {
    getList();
  }

  async function onDelete({ id }) {
    const postData = {
      id: id,
    };
    const response = await sendPost({
      endPoint: DELETE_PRODUCT,
      postData: postData,
    });
    if (response.success) {
      createSnackbar({ data: { type: 2, message: "Ürün Silme Başarılı" } });
    } else {
      createSnackbar({ data: { type: 1, message: response.errorText } });
    }
    destoryModal();
    getList();
  }

  return (
    <div className="flex flex-col h-screen w-full">
      <>
        <AppAddNewItem
          onClick={() => {
            createModal({
              title: "Ürün Oluştur",
              name: createOrEditUrun,
              data: { isEdit: false, onCreateSuccess },
            });
          }}
        ></AppAddNewItem>
        {loading ? (
          <div> Yükleniyor</div>
        ) : (
          <div
            className=" 
        grid
        grid-cols-2
        lg:grid-cols-3
        xl:grid-cols-4
        gap-5
        flex-col h-max w-max
        gap-y-0
        "
          >
            {data.map((item, index) => (
              <UrunTile
                onDeleteSuccess={() => {
                  console.log(item);
                  onDelete({ id: item?.id });
                }}
                key={index}
                onEditSuccess={onEditSuccess}
                item={item}
              ></UrunTile>
            ))}
          </div>
        )}
      </>
    </div>
  );
}
