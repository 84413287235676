import React from "react";
import { destoryModal, useModals } from "../utils/extensions/modal";
import { useSnackbars } from "../utils/extensions/SnackbarExtension";
import HomeScreen from "../screens/home_screen/HomeScreen";
import DashboardScreen from "../screens/home_screen/dashboard_screen/dashboard_screen";
import UrunlerScreen from "../screens/home_screen/urunler_screen/UrunlerScreen";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppSnackBar from "../components/AppSnackBar";
import AppModal from "../components/AppModal";
import { useAuth } from "../utils/extensions/AuthExtension";
import LoginScreen from "../screens/login_screen/LoginScreen";
import KategoriScreen from "../screens/home_screen/kategori_screen/KategoriScreen";
import AttributesScreen from "../screens/home_screen/attributes_screen/AttributesScreen";

export default function AppRoutes() {
  const currentUser = useAuth();
  const modals = useModals();
  const snackbars = useSnackbars();
  const isLogin = currentUser.token !== undefined;

  return (
    <>
      <BrowserRouter>
        {snackbars.length > 0 ? <AppSnackBar></AppSnackBar> : null}
        {modals.length > 0 ? (
          <AppModal closeModal={destoryModal}></AppModal>
        ) : null}
        <Routes>
          <Route path="/" element={<LoginScreen />} />
          {
            //gizli olması gereken routelar
          }
          {isLogin === true && (
            <>
              <Route path="/home" element={<HomeScreen />}>
                <Route path="" element={<DashboardScreen />}></Route>
                <Route path="urunler" element={<UrunlerScreen />}></Route>
                <Route path="kategories" element={<KategoriScreen />}></Route>
                <Route path="ozellikler" element={<AttributesScreen />}></Route>
              </Route>
            </>
          )}
          <Route
            path="*"
            element={isLogin ? <HomeScreen /> : <LoginScreen />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}
