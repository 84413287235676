import React, { useState } from "react";
import KategoriHook from "./KategoriHook";
import AppAddNewItem from "../../../components/AppAddNewItem";
import CreateOrEditCategoriModal from "../../../components/modals/create_or_edit_categori_modal/CreateOrEditCategoriModal";
import {
  areYouSore,
  createOrEditCategori,
} from "../../../components/modals/modal";
import { createModal } from "../../../utils/extensions/modal";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function KategoriScreen() {
  const kategoriHook = KategoriHook();
  const { kategoriRepo } = kategoriHook;

  return (
    <div className="h-full w-full">
      {kategoriRepo?.loading === true ? (
        <div> yükleniyor </div>
      ) : (
        <KategoriBody hook={kategoriHook}></KategoriBody>
      )}
    </div>
  );
}

function KategoriBody({ hook }) {
  const { onEditSuccess, kategoriRepo } = hook;

  return (
    <div className="h-full w-full flex flex-col">
      <AppAddNewItem
        title="Yeni Kategori Ekle"
        onClick={() => {
          createModal({
            title: "Kategori Oluştur",
            name: createOrEditCategori,
            data: { isEdit: false, onEditSuccess },
          });
        }}
      ></AppAddNewItem>
      {kategoriRepo?.getTopCategori()?.map((e) => (
        <KategoriListTile item={e} hook={hook}></KategoriListTile>
      ))}
    </div>
  );
}

function KategoriListTile({ item, hook }) {
  const [hover, setHover] = useState(false);
  const { onDelete, kategoriRepo } = hook;

  const { onEditSuccess } = kategoriRepo;

  const listSubCategories = kategoriRepo.getSubCategories(item.id);

  return (
    <>
      <div
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        onClick={() => {
          createModal({
            title: "Kategori Güncelle",
            name: createOrEditCategori,
            data: { ...item, isEdit: true, onEditSuccess },
          });
        }}
        className="p-2 hover:bg-slate-100 hover:cursor-pointer w-full"
      >
        <div className="flex flex-col justify-start  items-start  w-full">
          <div className="text-black flex flex-row justify-between w-full">
            <div className="flex flex-1">{item?.name}</div>

            {hover === true && (
              <div className="">
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    createModal({
                      title: "Ürün Sil",
                      name: areYouSore,
                      data: {
                        text: "Bu ürünü silmek istediğinizden emin misiniz?",
                        onContinue: () => {
                          onDelete({ id: item.id });
                        },
                      },
                    });
                  }}
                  className="p-4 hover:bg-gray-200 rounded-full flex  items-center justify-center z-10 h-3 w-3 bg-gray-200"
                >
                  <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="pl-10">
        {listSubCategories.map((e) => (
          <KategoriListTile item={e} hook={hook}></KategoriListTile>
        ))}{" "}
      </div>
    </>
  );
}
