import React from 'react'

export default function AppButton({ onclick, label, isLoading }) {
    return (
        <div className='w-full p-2'>
            <button onClick={onclick} className="h-11 bg-green-500 w-full p-2 text-white py-2 px-4 rounded-lg justify-center items-center flex ">
                <div>{(isLoading === true) ?
                    <div className="w-8 h-8 border-t-4 border-white-500 border-solid rounded-full animate-spin"></div>

                    : label}</div>
            </button>
        </div>

    )
}
