import React, { useEffect, useState } from "react";

export default function AppImagePickerHook() {
  const [image, setImage] = useState(null);

  const selfChanged = (v) => {};

  useEffect(() => {
    if (!image) return;
    selfChanged(image);
  }, [image]);

  return { image, setImage };
}
