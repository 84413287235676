import React from "react";
import modalList from "../components/modals/modal";

import { useModals } from "../utils/extensions/modal";
import AppModalTitle from "./AppModalTitle";
export default function AppModal({ closeModal }) {
  const modals = useModals();
  return (
    <>
      {modals.map((e1, i) => {
        const m = modalList.find((e2) => e1?.name === e2?.name);
        if (m !== undefined) {
          return (
            <div className="fixed inset-0 z-50 ">
              <div className="content-center self-center  flex items-center justify-center p-8">
                <div className="flex modal-bg fixed inset-0 bg-gray-800 opacity-75 items-center justify-center "></div>
                <div className="h-max flex flex-col modal-content bg-white pb-8 pl-5 pr-5 rounded shadow-lg z-50 self-center content-center  items-center justify-center animate-expand-center">
                  <AppModalTitle title={e1.title}></AppModalTitle>
                  <m.element
                    key={i}
                    probs={e1.data}
                    closeModal={closeModal}
                  ></m.element>
                </div>
              </div>
            </div>
          );
        }
      })}
    </>
  );
}
