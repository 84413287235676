import React from "react";

export default function AppAddNewItem({ onClick, title = "Yeni Ürün ekle" }) {
  return (
    <div className="flex flex-row items-end w-full justify-end pr-2 pt-2">
      <div
        onClick={onClick}
        className="bg-slate-50 p-2 hover:shadow-md cursor-pointer rounded-xl"
      >
        {title}
      </div>
    </div>
  );
}
