import { createSlice } from '@reduxjs/toolkit'

export const snackbar = createSlice({
    name: 'snackbar',
    initialState: {
        snackbars: [],
    },
    reducers: {
        append: (state, action) => {
            state.snackbars = [...state.snackbars, action.payload]
        },
        destory: state => {
            state.snackbars = state.snackbars.slice(0, -1)
        },
        destoryAll: state => {
            state.snackbars = [];
        }
    }
})

export const { append, destory, destoryAll } = snackbar.actions

export default snackbar.reducer