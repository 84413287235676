import React from "react";
import CreateOrEditAttrbiuteHook from "./CreateOrEditAttrbiuteHook";
import AppTextInput from "../../AppTextInput";
import AppButton from "../../AppButton";
import AppComboBox from "../AppComboBox/AppComboBox";

export default function CreateOrEditAttributeModal({ probs }) {
  var hook = CreateOrEditAttrbiuteHook(probs);
  return (
    <div className="p-5">
      <div className="w-[80vw] h-[80vh] overflow-auto flex flex-col">
        <AppTextInput
          placeholder={"Giriniz"}
          label={"Özellik Adı"}
          onchanged={hook?.handleOnChange}
          name={"name"}
          value={hook?.formData.name}
        ></AppTextInput>

        <AppTextInput
          placeholder={"Giriniz"}
          label={"Özellik Açıklaması"}
          onchanged={hook?.handleOnChange}
          name={"description"}
          value={hook?.formData.description}
        ></AppTextInput>

        <AppComboBox comboboxHook={hook.attrbiuteComboBoxHook}></AppComboBox>

        <AppButton
          isLoading={hook?.isLoading}
          label={"Kaydet"}
          onclick={hook?.handleOnSave}
        ></AppButton>
      </div>
    </div>
  );
}
