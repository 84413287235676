import React, { useEffect, useRef, useState } from "react";
import AppButton from "../AppButton";
import AppTextInput from "../AppTextInput";

import AppComboBox from "./AppComboBox/AppComboBox";
import { priceCurrency } from "../../utils/constans/datas";
import NetworkService from "../../managers/network_manager";
import { CREATE_PRODUCT, UPDATE_PRODUCT } from "../../utils/constans/end_point";
import { createSnackbar } from "../../utils/extensions/SnackbarExtension";

import AppHtmlEditor from "../AppHtmlEditor";

import AppImagePickerHook from "../app_image_picker/app_image_picker_hook";
import AppImagePicker from "../app_image_picker/AppImagePicker";
import KategoriRepository from "../../repository/kategori_repository";
import AttributeRepository from "../../repository/attribute_repository";
import AppComboBoxHook from "./AppComboBox/AppComboBoxHook";

export default function CreateOrEditModal({ probs, closeModal }) {
  const kategoriRepo = KategoriRepository({ id: "" });
  const attributeRepo = AttributeRepository({ id: "" });

  const kategoriComboBoxHook = AppComboBoxHook({
    label: "Kategoriler",
    selectMany: true,
  });

  const attrbiuteComboBoxHook = AppComboBoxHook({
    label: "Özellikler",
    selectMany: true,
  });

  const priceComboBoxHook = AppComboBoxHook({
    label: "Para Birimi",
    selectMany: false,
  });

  useEffect(() => {
    kategoriComboBoxHook.setIsLoading(kategoriRepo.loading);
  }, [kategoriRepo.loading]);

  useEffect(() => {
    kategoriComboBoxHook.setItems(kategoriRepo.listForCombobox());
  }, [kategoriRepo.data]);

  useEffect(() => {
    kategoriComboBoxHook.fillSelectedOptionsFromIds(probs.categories);
  }, [kategoriComboBoxHook.items]);

  useEffect(() => {
    attrbiuteComboBoxHook.fillSelectedOptionsFromIds(probs.attributes);
  }, [attrbiuteComboBoxHook.items]);

  useEffect(() => {
    attrbiuteComboBoxHook.setItems(attributeRepo.listForCombobox("", true));
  }, [attributeRepo.data]);

  useEffect(() => {
    priceComboBoxHook.setSelectedOption(findSelected());
    priceComboBoxHook.setItems(priceCurrency);
  }, [probs]);

  const imagePicker = AppImagePickerHook({ propName: "imageUrl" });

  const [htmlValue, setHtmlValue] = useState(null);

  const { sendPost, uploadFile } = NetworkService();

  const [formData, setFormData] = useState({
    name: probs?.name,
    amount: probs?.price?.amount,
    currency: probs?.price?.currency,
    showValue: findSelected(),
    description: probs?.description,
    image: probs?.image,
    breadcrumbName: probs?.breadcrumbName,
    goingToUrl: probs?.goingToUrl,
  });

  const [isLoading, setIsLoading] = useState(false);

  async function handleOnSave() {
    setIsLoading(true);

    if (probs.isEdit === true) {
      await editOne();
      if (probs.onEditSuccess != undefined) {
        probs.onEditSuccess();
      }
    } else {
      await createOne();
      if (probs.onCreateSuccess != undefined) {
        probs.onCreateSuccess();
      }
    }

    setIsLoading(false);
  }
  async function editOne() {
    debugger;

    var bodyFormData = new FormData();
    bodyFormData.append("file", imagePicker?.image);

    const response1 = await uploadFile({ file: bodyFormData });

    const postData = {
      id: probs.id,
      name: formData.name,
      price: {
        amount: formData.amount,
        currency: priceComboBoxHook.selectedOption.key,
      },
      description: formData.description,
      imageUrl: response1?.data,
      categories: kategoriComboBoxHook.selectedOptions?.map((e) => e.key),
      attributes: attrbiuteComboBoxHook.selectedOptions?.map((e) => e.key),
      breadcrumbName: formData.breadcrumbName,
      goingToUrl: formData.goingToUrl,
    };

    const response = await sendPost({
      endPoint: UPDATE_PRODUCT,
      postData: postData,
    });
    if (response.success) {
      createSnackbar({
        data: { type: 2, message: "Ürün Güncelleme Başarılı" },
      });
      closeModal();
    } else {
      createSnackbar({ data: { type: 1, message: response.errorText } });
    }
  }

  async function createOne() {
    var bodyFormData = new FormData();

    bodyFormData.append("file", imagePicker?.image);

    const response1 = await uploadFile({ file: bodyFormData });

    const postData = {
      name: formData.name,
      price: {
        amount: formData.amount,
        currency: priceComboBoxHook.selectedOption.key,
      },
      description: formData.description,
      imageUrl: response1?.data,
      categories: kategoriComboBoxHook.selectedOptions?.map((e) => e.key),
      attributes: attrbiuteComboBoxHook.selectedOptions?.map((e) => e.key),
      breadcrumbName: formData.breadcrumbName,
      goingToUrl: formData.goingToUrl,
    };
    const response = await sendPost({
      endPoint: CREATE_PRODUCT,
      postData: postData,
    });
    if (response.success) {
      createSnackbar({ data: { type: 2, message: "Ürün Ekleme Başarılı" } });
      closeModal();
    } else {
      createSnackbar({ data: { type: 1, message: response.errorText } });
    }
  }

  function findSelected() {
    const selected = priceCurrency.find((e) => e.key == probs?.price?.currency);
    return selected;
  }

  function handleOnChange(e) {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  function handleOnChangeCombo(e) {
    console.log(e);
    const { name, key } = e;
    setFormData({ ...formData, [name]: key });
    console.log(formData);
  }

  return (
    <div className="">
      <div className="w-[80vw] h-[80vh] overflow-auto justify-between items-center content-center self-center justify-self-center">
        <div className="flex flex-col ">
          <AppTextInput
            placeholder={"Giriniz"}
            label={"Ürün Adı"}
            onchanged={handleOnChange}
            name={"name"}
            value={formData.name}
          ></AppTextInput>
          <AppTextInput
            placeholder={"Giriniz"}
            label={"Ürün Fiyatı"}
            onchanged={handleOnChange}
            name={"amount"}
            value={formData.amount}
          ></AppTextInput>
          <AppTextInput
            placeholder={"Giriniz"}
            label={"Breadcrumb Adı"}
            onchanged={handleOnChange}
            name={"breadcrumbName"}
            value={formData.breadcrumbName}
          ></AppTextInput>

          <AppTextInput
            placeholder={"Giriniz"}
            label={"Gidilecek url"}
            onchanged={handleOnChange}
            name={"goingToUrl"}
            value={formData.goingToUrl}
          ></AppTextInput>
          <AppComboBox comboboxHook={priceComboBoxHook}></AppComboBox>

          <AppComboBox comboboxHook={kategoriComboBoxHook}></AppComboBox>

          <AppComboBox comboboxHook={attrbiuteComboBoxHook}></AppComboBox>

          <AppHtmlEditor
            propName={"description"}
            content={formData.description}
            label={"Açıklama"}
            onChanged={handleOnChange}
          ></AppHtmlEditor>

          <AppImagePicker imagePickerHook={imagePicker}></AppImagePicker>

          <AppButton
            isLoading={isLoading}
            label={"Kaydet"}
            onclick={handleOnSave}
          ></AppButton>
        </div>
      </div>
    </div>
  );
}
