import { Outlet } from "react-router-dom";

import { useEffect } from "react";
import runOneSignal from "../managers/one_signal_manager";

function App() {
  useEffect(() => {
    runOneSignal();
  }, []);
  return <Outlet></Outlet>;
}

export default App;
