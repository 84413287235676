import React, { useEffect, useState } from "react";
import AppTextInput from "../../AppTextInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const AppComboBox = ({ comboboxHook }) => {
  const {
    makeLabelForMany,
    selectedOption,
    setIsOpen,
    label,
    name,
    selectMany,
    handleSelect,
    isContain,
    isLoading,
    sufIcon,
    isOpen,
    items,
  } = comboboxHook || {};
  return (
    <div className="flex h-24 flex-col w-full relative">
      {isLoading !== true ? (
        <AppTextInput
          name={name}
          value={selectMany ? makeLabelForMany() : selectedOption?.showValue}
          onFocus={(e) => {
            setIsOpen(true);
          }}
          unFocus={() => {
            if (selectMany == true) return;
            setTimeout(() => {
              setIsOpen(false);
            }, 100);
          }}
          readOnly={true}
          label={label}
          sufIcon={sufIcon !== undefined ? sufIcon() : null}
        ></AppTextInput>
      ) : (
        <div> yükleniyor </div>
      )}
      {isOpen && (
        <div className="z-50 flex w-full pl-2 pr-2">
          <div className="w-full  bg-white border rounded-md shadow-lg">
            {items?.map((option, index) => (
              <div
                className="hover:bg-slate-100 cursor-pointer p-2 w-full flex flex-row"
                key={index}
                onClick={() => handleSelect(option)}
              >
                <div className="flex-1"> {option.showValue} </div>

                {isContain(option.key) === true ? <div>x</div> : null}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AppComboBox;
