import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

function AppComboBoxHook({ name, label, selectMany, onChanged }) {
  const [items, setItems] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [selectedOption, setSelectedOption] = useState({
    showValue: "",
  });

  const fillSelectedOptionFromId = (id) => {
    const listSelectedItems = items.find((e) => {
      const isContain = e.key == id;

      return isContain;
    });

    setSelectedOption(listSelectedItems);
  };

  const fillSelectedOptionsFromIds = (ids) => {
    const listSelectedItems = items.filter((e) => {
      const isContain = ids?.some((e1) => e.key == e1);

      return isContain;
    });

    setSelectedOptions(listSelectedItems);
  };

  const [selectedOptions, setSelectedOptions] = useState([]);
  const isContain = (id) => {
    const isTrue = selectedOptions?.some((e) => e.key == id);
    return isTrue;
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (option) => {
    setSelectedOption(option);

    if (selectMany) {
      if (!isContain(option.key)) {
        setSelectedOptions([...selectedOptions, option]);
      } else {
        const newList = selectedOptions.filter((e) => e.key != option.key);
        setSelectedOptions(newList);
      }
    }

    if (!selectMany) {
      setIsOpen(false);
    }
  };

  const makeLabelForMany = () => {
    const text = selectedOptions.map((e) => e.showValue).join();
    return text;
  };

  const sufIcon = () => {
    return (
      isOpen && (
        <div
          className="cursor-pointer h-5 w-5 hover:pointer"
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(false);
          }}
        >
          <FontAwesomeIcon className="text-gray-900" icon={faClose} />
        </div>
      )
    );
  };

  return {
    sufIcon,
    makeLabelForMany,
    handleSelect,
    selectedOption,
    setSelectedOption,
    selectedOptions,
    setIsOpen,
    isOpen,
    isLoading,
    items,
    setItems,
    setIsLoading,
    name,
    label,
    isContain,
    selectMany,
    fillSelectedOptionsFromIds,
    fillSelectedOptionFromId,
  };
}

export default AppComboBoxHook;
