import React, { Fragment } from "react";
import ReactDOM, { createRoot } from "react-dom/client";
import "./index.css";
import App from "./screens/App";
import reportWebVitals from "./reportWebVitals";

import "./styles/tailwind.css";
import { Provider } from "react-redux";
import store from "./utils/redux/redux_index";
import AppRoutes from "./routes/AppRoutes";

const root = document.getElementById("root");

if (root) {
  createRoot(root).render(
    <Provider store={store}>
      <AppRoutes></AppRoutes>
    </Provider>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
