import axios from "axios";
import { BASE_URL } from "../utils/constans/api_config";
import { useAuth } from "../utils/extensions/AuthExtension";

function NetworkService() {
  const currentUser = useAuth();

  const sendPost = async ({ endPoint, postData }) => {
    const _headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + currentUser?.token,
    };

    const response = await axios.post(BASE_URL + endPoint, postData, {
      headers: _headers,
    });

    return response.data;
  };

  const uploadFile = async ({ file }) => {
    const _headers = {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + currentUser?.token,
    };

    const response = await axios.post(BASE_URL + "upload", file, {
      headers: _headers,
    });

    return response.data;
  };

  const sendGet = async ({ endPoint }) => {
    const _headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + currentUser?.token,
    };

    const response = await axios.get(BASE_URL + endPoint, {
      headers: _headers,
    });

    return response.data;
  };

  return { sendGet, sendPost, uploadFile };
}

export default NetworkService;
