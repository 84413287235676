import React, { useCallback, useEffect, useState } from "react";
import NetworkService from "../managers/network_manager";
import { GET_KATEGORI } from "../utils/constans/end_point";
import { createSnackbar } from "../utils/extensions/SnackbarExtension";

export default function KategoriRepository({ id = "" }) {
  const [selected, setSelected] = useState(null);

  const { sendGet } = NetworkService();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (id != "") {
      setSelectedFromId(id);
    }
  }, [data]);

  const setSelectedFromId = useCallback(
    (id) => {
      {
        const tempSelected = data.find((e) => e?.id == id);

        setSelected({
          key: tempSelected?.id,
          showValue: tempSelected?.name,
        });
      }
    },
    [data]
  );

  const onChangedSelected = (v) => {
    setSelected(v);
  };

  const categoriFromId = () => {
    const tempSelected = data.find((e) => e?.id == selected?.key);
    return {
      key: tempSelected?.id,
      showValue: tempSelected?.name,
    };
  };

  const listForCombobox = (currentId, removeTopAttbiute) => {
    debugger;
    var tempData = data.filter((e) => e.id !== currentId);

    if (removeTopAttbiute === true) {
      tempData = tempData.filter((e) => e.topCategoriId !== null);
    }
    return tempData.map((e) => {
      return {
        key: e.id,
        showValue: e.name,
      };
    });
  };

  const onEditSuccess = () => {
    getList();
  };

  const getList = async () => {
    setLoading(true);
    const response = await sendGet({ endPoint: GET_KATEGORI });
    if (!response.success) {
      createSnackbar({ data: { type: 2, message: response.errorText } });
    }
    if (response.success) {
      setData(response.data);
    }
    setLoading(false);
  };

  const getTopCategori = () => {
    return data.filter((e) => e.topCategoriId === null);
  };

  const getSubCategories = (id) => {
    return data.filter((e) => e.topCategoriId === id);
  };

  useEffect(() => {
    getList();
  }, []);

  return {
    loading,
    data,
    getList,
    onChangedSelected,
    listForCombobox,
    categoriFromId,
    selected,
    onEditSuccess,
    getTopCategori,
    getSubCategories,
  };
}
