import React, { Fragment, useEffect, useRef, useState } from 'react'
import AppTextInput from '../../components/AppTextInput'
import AppButton from '../../components/AppButton'

import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { createModal, destoryModal, useModals } from '../../utils/extensions/modal';

import { verifySms } from '../../components/modals/modal';
import NetworkService from '../../managers/network_manager';
import { BASE_URL } from '../../utils/constans/api_config';
import { LOGIN_AUTHENTICATE } from '../../utils/constans/end_point';
import { createSnackbar } from '../../utils/extensions/SnackbarExtension';



export default function LoginScreen() {
  const { sendPost } = NetworkService();
  const [isVisible, setIsVisible] = useState(false);

  const handleOnVisibleChange = (e) => {
    e.stopPropagation();
    setIsVisible(!isVisible);
  }


  var formData = useRef({
    phone: '',
  });


  const [loading, setLoading] = useState(false);

  function handleOnChange(e) {
    const { name, value } = e.target;
    formData.current[name] = value;
  }

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const response = await sendPost({ endPoint: LOGIN_AUTHENTICATE, postData: formData.current });

    if (!response.success) {
      createSnackbar({ data: { type: 1, message: response.errorText } })


    }
    if (response.success === true) {
      createModal({ title: "Sms Onayla", name: verifySms, data: formData.current });
      createSnackbar({ data: { type: 2, message: "Onay Kodu Gönderildi" } })
    }

    setLoading(false);

    // Burada form verisini kullanabilirsiniz (örneğin, API'ye gönderme).
  };

  const sufIcon = () => {
    return <div className=' cursor-pointer h-5 w-5 hover:pointer' onClick={handleOnVisibleChange} ><FontAwesomeIcon className='text-gray-900' icon={isVisible ? faEye : faEyeSlash} /> </div>
  }

  return (


    <div className="flex flex-col justify-center items-center h-screen bg-slate-200">

      {/* Genişlik 50 ve yükseklik 50 olan kutucuk */}
      <div className="sm:w-1/3 lg:w-1/3 p-9  w-8/12 bg-white items-center  justify-center flex shadow-lg rounded-lg flex-col">
        {/* Kutucuk içeriği */}


        <AppTextInput
          name={"phone"}
          label={"Telefon Numaranız"}
          placeholder={"Yazınız"}
          onchanged={handleOnChange}>

        </AppTextInput >

        <AppButton
          isLoading={loading}
          label={"Giriş Yap"}
          onclick={handleSubmit}
        >


        </AppButton>

      </div>
    </div>
  )
}
