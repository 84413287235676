import { configureStore } from "@reduxjs/toolkit";
import authRedux from "./auth_redux/AuthRedux";
import ModalRedux from "./modal_redux/ModalRedux";
import ProductLists from "./product_redux/ProductRedux";
import SnackbarRedux from "./snackbar_redux/SnackbarRedux";

export default configureStore({
  reducer: {
    auth: authRedux,
    modal: ModalRedux,
    productList: ProductLists,
    snackbar: SnackbarRedux,
  },
});
