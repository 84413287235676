import React from 'react'
import AppButton from '../AppButton'

export default function AreYouSureModal({ probs, closeModal }) {

    return (
        <div>
            <div>{probs.text} </div>

            <AppButton label={"Devam Et"} onclick={() => {
                if (probs.onContinue !== undefined) {
                    probs.onContinue();
                }

            }}></AppButton>
        </div>

    )
}
