import React from 'react'
import { sideBarItems } from '../../../../utils/constants'
import SidebarListTile from './SidebarListTile'

export default function Sidebar() {
    return (
        <div className='p-2 h-full pb-2 bg-slate-300' >
            <div className='p-2 bg-white h-full  rounded-lg shadow-lg flex flex-col justify-between'>
                {
                    // top section
                }
                <div className='flex flex-col items-start'>
                    {sideBarItems.map((e) => <SidebarListTile key={e.id} props={e}></SidebarListTile>)}
                </div>
                {
                    // bottom section
                }

            </div>
        </div>

    )
}
