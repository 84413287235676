import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { destoryModal } from '../utils/extensions/modal';
export default function AppModalTitle({ title }) {

    return (
        <div className='flex flex-row justify-between w-full pt-2 pb-1 items-center' >
            <div></div>
            <div className='text-xl text-gray-900' >
                {title}

            </div>
            <FontAwesomeIcon onClick={destoryModal} style={{ fontSize: '1.5em' }} className=' cursor-pointer text-gray-600 hover:shadow-md p-2 hover:bg-gray-100 rounded-full' icon={faClose}></FontAwesomeIcon>
        </div>
    )
}
