import React from "react";
import Sidebar from "./components/sidebar/Sidebar";
import Mainbar from "./components/Mainbar";
import { useSelector } from "react-redux";

export default function HomeScreen() {
  return (
    <>
      <div class="flex flex-row h-screen w-screen">
        <div class="w-1/5 h-screen ">
          <Sidebar></Sidebar>
        </div>

        <div class="w-4/5 h-screen">
          <Mainbar></Mainbar>
        </div>
      </div>
    </>
  );
}
